import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import config from '../../../../gatsby-config';

// Components
import MainLayout from '../../../components/main-layout';
import HeaderSection from './header-section';
import FirstSection from './first-section';
import SecondSection from './second-section';
import ThirdSection from './third-section';
import ImInterestedForm from './im-interested-form';

import biuwerFreeQuickStartPackageImgEn from '../../../../static/img/biuwer-free-quick-start-package.png';
import biuwerFreeQuickStartPackageImgEs from '../../../../static/img/biuwer-pack-inicio-gratuito.png';

const FreeQuickStartPackage = (props) => {
    const { pageContext } = props,
        { i18n, t } = useTranslation(),
        siteUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : config.siteMetadata.siteUrl,
        lang = pageContext.lang || i18n.language,
        img = lang === 'en' ? biuwerFreeQuickStartPackageImgEn : biuwerFreeQuickStartPackageImgEs,
        imgUrl = `${siteUrl}${img}`;

    return (
        <MainLayout pageContext={pageContext}>
            <Helmet>
                <meta property="og:image" content={imgUrl} />
                <meta name="twitter:image" content={imgUrl} />
            </Helmet>

            <HeaderSection t={t} />
            <FirstSection t={t} />
            <SecondSection t={t} lang={lang} />
            <ThirdSection t={t} />
            <ImInterestedForm t={t} />
        </MainLayout>
    );
};

export default FreeQuickStartPackage;