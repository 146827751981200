/** @jsx jsx */
import { jsx } from '@emotion/react';
import { green } from 'material-colors';

const SecondSection = (props) => {
    const { t } = props;

    const styles = {
        icon: {
            fontSize: 24,
            color: green[500]
        }
    };

    return (
        <section>
            <div className="container">
                <div className="row mb30 pt40">
                    <div className="col-12 col-xl-6 pb20">
                        <div className="pb20 text-center">
                            <h4>{t('lp:freeQuickStartPackage.valuedAt')}</h4>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-tick bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('lp:freeQuickStartPackage.plansToPurchase')}</h5>
                            </div>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-tick bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('lp:freeQuickStartPackage.20HoursIncluded')}</h5>
                            </div>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-tick bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('lp:freeQuickStartPackage.50HoursIncluded')}</h5>
                            </div>
                        </div>
                        <h6 className="mt30 text-center">{t('lp:freeQuickStartPackage.onlyUntil')}</h6>
                        <h6 className="mb20 text-center">{t('lp:freeQuickStartPackage.onlyFor')}</h6>
                    </div>
                    <div className="col-12 col-xl-6 pb20">
                        <div className="pb20">
                            <h5>{t('lp:freeQuickStartPackage.whatsIncluded')}</h5>
                        </div>
                        <div>
                            <ul>
                                <li>{t('lp:freeQuickStartPackage.included1')}</li>
                                <li>{t('lp:freeQuickStartPackage.included2')}</li>
                                <li>{t('lp:freeQuickStartPackage.included3')}</li>
                                <li>{t('lp:freeQuickStartPackage.included4')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecondSection;