import React, { useState, useEffect } from 'react';
import validator from 'validator'
import { Button, Callout } from "@blueprintjs/core";
import TextInput from '../../../components/text-input';
import TextArea from '../../../components/text-area';

const ImInterestedForm = (props) => {
    const { t } = props;

    const [email, setEmail] = useState('');
    const [emailErrorText, setEmailErrorText] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);
    const [formSubmittedMessage, setFormSubmittedMessage] = useState('');

    const cleanForm = () => {
        setEmail('');
        setEmailErrorText('');
        setFirstName('');
        setLastName('');
        setCompany('');
        setPhone('');
        setMessage('');
        setFormSubmittedMessage('');
    };

    const validateForm = () => {

        // Email
        let emailValid, emailError;
        if(!!email) {
            if(validator.isEmail(email)) {
                emailValid = true;
            } else {
                emailValid = false;
                emailError = t('contactPage.form.emailErrorText');
            }
        } else {
            emailValid = false;
            emailError = null;
        }

        if(emailValid && !!firstName && !!company) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
            setEmailErrorText(emailError);
        }
    };

    const sendEmail = () => {
        const name = `${firstName} ${lastName}`;

        // Postmark mail
        const msg = {
            TemplateId: 18668803,
            TemplateModel: {
                'email': email,
                'firstName': firstName,
                'lastName': lastName,
                'company': company,
                'phone': phone,
                'message': message
            },
            To: `Biuwer Team <info@biuwer.com>`,
            From: `Biuwer Team <info@biuwer.com>`,
            ReplyTo: `${name} <${email}>`
        };

        if(canSubmit) {
            setCanSubmit(false);
            setFormSubmittedMessage(t('contactPage.form.formSubmittedMessage'));
            window.analytics.track('Send Email', msg);

            // Clean messages after 5 sec
            setTimeout(cleanForm, 5000);
        }
    };

    // Validate the form on any field change
    useEffect(() => {
        validateForm();
    }, [email, firstName, lastName, company, phone, message]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section id="iminterested">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12 mb30">
                        <h2>{t('lp:freeQuickStartPackage.startNow')}</h2>
                        <h4>{t('lp:freeQuickStartPackage.completeForm')}</h4>
                    </div>
                </div>
                <div className="row" key="FormRow1">
                    <div className="col-12 mb10">
                        <TextInput
                            formStyling="default"
                            required
                            type="text"
                            name="email"
                            labelText={t('contactPage.form.emailLabel').toUpperCase()}
                            placeholder={t('contactPage.form.emailPlaceHolder')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            errorText={emailErrorText}
                        />
                    </div>
                </div>
                <div className="row" key="formRow2">
                    <div className="col-6 mb10">
                        <TextInput
                            formStyling="default"
                            required
                            type="text"
                            name="firstName"
                            labelText={t('contactPage.form.firstNameLabel').toUpperCase()}
                            placeholder={t('contactPage.form.firstNameLabel')}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="col-6 mb10">
                        <TextInput
                            formStyling="default"
                            type="text"
                            name="lastName"
                            labelText={t('contactPage.form.lastNameLabel').toUpperCase()}
                            placeholder={t('contactPage.form.lastNameLabel')}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="col-6 mb10">
                        <TextInput
                            formStyling="default"
                            required
                            type="text"
                            name="company"
                            labelText={t('contactPage.form.companyLabel').toUpperCase()}
                            placeholder={t('contactPage.form.companyLabel')}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </div>
                    <div className="col-6 mb10">
                        <TextInput
                            formStyling="default"
                            type="text"
                            name="phone"
                            labelText={t('contactPage.form.phoneLabel').toUpperCase()}
                            placeholder={t('contactPage.form.phoneLabel')}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row" key="formRow3">
                    <div className="col-12 mb10">
                <TextArea
                    formStyling="default"
                    name="message"
                    labelText={t('contactPage.form.messageLabel').toUpperCase()}
                    placeholder={t('contactPage.form.messagePlaceHolder')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                    </div>
                </div>
                <div className="row" key="formRow4">
                    {!!formSubmittedMessage ? (
                        <div className="col mb20">
                            <Callout
                                intent="success"
                                title={t('contactPage.form.formSubmittedLabel')}
                            >
                                {formSubmittedMessage}
                            </Callout>
                        </div>
                    ) : null}
                </div>
                <div className="row" key="formRow5">
                    <div className="col-12 mb40">
                        <Button
                            type="submit"
                            disabled={!canSubmit}
                            rightIcon="arrow-right"
                            intent="primary"
                            text={t('contactPage.form.submitLabel')}
                            onClick={sendEmail}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ImInterestedForm;