/** @jsx jsx */
import { jsx } from '@emotion/react';

import config from '../../../../gatsby-config';
import headerImg from '../../../../static/img/stars-in-the-night.jpg';

const FreeQuickStartPackageHeaderSection =  (props) => {
    const { t } = props;

    return (
        <header
            id="headerSection"
            key="header"
            css={styles.mainSection}
        >
            <div className="container-fluid pt30">
                <div className="row text-center">
                    <div className="col-12">
                        <h1 style={{ color: 'white' }}>{t('lp:freeQuickStartPackage.title')}</h1>
                        <h3 className="mv30" style={{ color: 'white' }}>{t('lp:freeQuickStartPackage.subTitle')}</h3>
                    </div>
                </div>
            </div>
        </header>
    );
};

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        minHeight: 300,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    }
};

export default FreeQuickStartPackageHeaderSection;