import { amber } from 'material-colors';
/** @jsx jsx */
import { jsx } from '@emotion/react';

const FirstSection = (props) => {
    const { t } = props;

    const styles = {
        icon: {
            fontSize: 24,
            color: amber[500]
        }
    };

    return (
        <section>
            <div className="container">
                <div className="row text-center pv40">
                    <div className="col-12">
                        <div className="pb20">
                            <h3>{t('lp:freeQuickStartPackage.question1')}</h3>
                        </div>
                        <div className="pb10" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-globe bp3-icon-large" style={styles.icon} />
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <h5>{t('lp:freeQuickStartPackage.question2')}</h5>
                            </div>
                        </div>
                        <div className="pb10" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-series-search bp3-icon-large" style={styles.icon} />
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <h5>{t('lp:freeQuickStartPackage.question3')}</h5>
                            </div>
                        </div>
                        <div className="pb10" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-horizontal-bar-chart-desc bp3-icon-large" style={styles.icon} />
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <h5>{t('lp:freeQuickStartPackage.question4')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FirstSection;