import { AnchorLink } from 'gatsby-plugin-anchor-links';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { green } from 'material-colors';
import { Button } from '@blueprintjs/core';

import biuwerFreeQuickStartPackageImgEn from '../../../../static/img/biuwer-free-quick-start-package.png';
import biuwerFreeQuickStartPackageImgEs from '../../../../static/img/biuwer-pack-inicio-gratuito.png';

const SecondSection = (props) => {
    const { t, lang } = props;

    const styles = {
        icon: {
            fontSize: 24,
            color: green[500]
        }
    };

    return (
        <section>
            <div className="container">
                <div className="row pb20">
                    <div className="col-12 col-xl-6 pb20 text-center">
                        <img
                            src={lang === 'en' ? biuwerFreeQuickStartPackageImgEn : biuwerFreeQuickStartPackageImgEs}
                            width={'95%'}
                            alt={t('lp:freeQuickStartPackage.title')}
                        />
                    </div>
                    <div className="col-12 col-xl-6">
                        <div className="pb20">
                            <h4>{t('lp:freeQuickStartPackage.getTheMostOfYourData')}</h4>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-tick bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('lp:freeQuickStartPackage.benefit1')}</h5>
                            </div>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-tick bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('lp:freeQuickStartPackage.benefit2')}</h5>
                            </div>
                        </div>
                        <div style={{ paddingLeft: 20, display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-tick bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('lp:freeQuickStartPackage.benefit3')}</h5>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <AnchorLink
                                to={lang === 'en' ? "/en/lp/free-quick-start-package/#iminterested" : "/es/lp/paquete-inicio-rapido-gratuito/#iminterested"}
                                title={t('lp:freeQuickStartPackage.imInterested').toUpperCase()}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    text={t('lp:freeQuickStartPackage.imInterested').toUpperCase()}
                                    intent="success"
                                    style={{ boxShadow: 'none', marginTop: 20, marginBottom: 20 }}
                                    large={true}
                                />
                            </AnchorLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecondSection;